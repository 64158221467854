import React from 'react';
import ModalVideo from 'react-modal-video';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';

class ProductMediaBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  render() {
    const { image, videoId, ariaLabel } = this.props.fields;
    const content = (
      <div className="video-banner">
        <Image field={image} />
      </div>
    );

    return (
      <div className="product-media-banner">
        <div className="l-padding">
          {videoId && videoId.value ? (
            <React.Fragment>
              <button
                className="video-modal-cta"
                type="button"
                onClick={() => this.setState({ isModalOpen: true })}
                aria-label={ariaLabel ? ariaLabel.value : 'Watch this video'}
              >
                {content}
              </button>
              <ModalVideo
                channel="youtube"
                isOpen={this.state.isModalOpen}
                onClose={() => this.setState({ isModalOpen: false })}
                videoId={videoId.value}
                youtube={{
                  autoplay: 1,
                  enablejsapi: 1,
                  rel: 0,
                }}
              />
            </React.Fragment>
          ) : (
            content
          )}
        </div>
      </div>
    );
  }
}

ProductMediaBanner.propTypes = PropTypes.SitecoreItem.inject({
  image: PropTypes.Image,
  videoId: PropTypes.SingleLineText,
  ariaLabel: PropTypes.SingleLineText,
});

ProductMediaBanner.defaultProps = DefaultProps.SitecoreItem.inject({
  image: DefaultProps.Image,
  videoId: DefaultProps.SingleLineText,
  ariaLabel: DefaultProps.SingleLineText,
});

export default ProductMediaBanner;
